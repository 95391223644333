import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom"
import "./index.css";


//const CORRECT_PIN_CODE = "111222";

const PinCode = () => {

  //  
  const { t, i18n } = useTranslation();
  const [ isPinCodeValid, setIsPinCodeValid ] = useState(true);
  const [ pinCode, setPinCode ] = useState("");
  const [ btnIsPressed, setBtnIsPressed ] = useState(false);
  const [ hiddenlabel, sethiddenlabel ] = useState(false);
  const [ isButtonDisabled, setButtonDisabled ] = useState(false);
  const [ isLabelDisplayed, setLabelDisplayed ] = useState(true);

  //  QUERY STRING
  let [searchParams] = useSearchParams()
  const QueryVal = searchParams.toString().substring(0, searchParams.toString().length - 1);
  //console.log(QueryVal);
  //console.log(QueryVal.length);
  //console.log(process.env.REACT_APP_QUERY_LENGTH);


  //  TRANSLATIONS
  //Creating a method to change the language onChnage from select box
  const changeLanguageHandler = (e) => {
    const languageValue = e.target.value
    i18n.changeLanguage(languageValue);
  }


  const disableButton = () => {
    setButtonDisabled(true);
    //alert("Button has been disabled!");
  };

  const enableButton = () => {
    setButtonDisabled(false);
    //alert("Button has been enabled!");
  };

  const ShowLabel = () => {
    setLabelDisplayed(true);
    //alert("Button has been disabled!");
  };

  const HideLabel = () => {
    setLabelDisplayed(false);
    //alert("Button has been enabled!");
  };

  //  CONTROL QUERY EMPTY  
  let queryBool = false;
  if(QueryVal.length == process.env.REACT_APP_QUERY_LENGTH){
    queryBool = true;
  } 


  //  CONTROL FUNCTION
  const checkPinCode = () => {

    //const isPinCodeValid = pinCode === CORRECT_PIN_CODE;

    setBtnIsPressed(true);
    setIsPinCodeValid(isPinCodeValid);
    //setisButtonDisabled(isButtonDisabled);

    // **** here's the timeout ****
    //setTimeout(() => this.setState({ isButtonDisabled: false }), 20000);
    setTimeout(() => { enableButton(); HideLabel(); }, 20000);
    disableButton();
    ShowLabel();



    if(QueryVal.length != process.env.REACT_APP_QUERY_LENGTH){
      //console.log('Longitud INCORRECTA')
    } else {
      if(pinCode != ''){
          fetch(process.env.REACT_APP_URL_API, {
          method: 'post',
          headers: {'Content-Type':'application/json'},
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          mode: "no-cors",
          body: JSON.stringify({
            EncryptedCode: QueryVal,
            RandomCode: pinCode
          })
        })
        
      .then(/*json => console.log(json)*/ sethiddenlabel(true))
      .catch(/*e => console.error(e)*/ sethiddenlabel(false)); 
      } 
    }

  };

  //  INPUT CODE VALUE DETECTION
  const handlePinChange = pinCode => {
    setPinCode(pinCode);
    setBtnIsPressed(false);
  };

  function setNativeValue(element, value) {
    let lastValue = element.value;
    element.value = value;
    let event = new Event("input", { target: element, bubbles: true });
    // React 15
    event.simulated = true;
    // React 16
    let tracker = element._valueTracker;
    if (tracker) {
        tracker.setValue(lastValue);
    }
    element.dispatchEvent(event);
}

    //  QUERY FILTER
    if(queryBool == true){
      //  DOM RENDER
      return (
        <>
          <div>
            <select className="custom-select" onChange={changeLanguageHandler}>
              <option value="en"> {t('app.ingles')} </option>
              <option value="sp"> {t('app.espanyol')} </option>
              <option value="fr"> {t('app.frances')} </option>
            </select>
          </div>

          <div className="container">
            <a href="https://ayr.es" target="_blank" rel="noreferrer"><div className="brand-logo"></div></a>
            <div className="brand-title">
              <p>{t('app.codigo.acceso')}</p>
            </div>
            
            <ReactCodeInput
              id="pinCode"
              type = 'numeric'
              keyboardType="numeric"
              inputmode="numeric"
              isValid={isPinCodeValid}
              fields={6}
              onChange={handlePinChange}
              value={pinCode}
            />

            <button type="submit" disabled={isButtonDisabled} onClick={checkPinCode}><p>{t('app.aceptar')}</p></button>

            <div style={{ display: isLabelDisplayed ? 'block' : 'none' }}>
            <label>{isPinCodeValid && btnIsPressed && t('app.action.progress')}</label>
            <label>{isPinCodeValid && btnIsPressed && t('app.esperar.tiempo')}</label>
            </div>

          </div>
          <div className = "Footer">
            
           
            
          </div>

        </>
      );
    }
};


export default PinCode;
